<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 10.146 5.801"
  >
    <path
      :style="iconStyle"
      d="M8.513,10.3l3.837-3.84a.722.722,0,0,1,1.024,0,.731.731,0,0,1,0,1.027l-4.347,4.35a.724.724,0,0,1-1,.021L3.649,7.491A.725.725,0,0,1,4.673,6.464Z"
      transform="translate(-3.438 -6.248)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '5.801',
    },
    width: {
      type: String,
      default: '10.146',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
